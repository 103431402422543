var bannerTimer;
var bannerDelay = 5000;
var photoDelay = 3000;
var currentYr = new Date().getFullYear();

$(document).ready(function(){
	$("#year").html(" "+ currentYr);

	$( "a.menuLink" ).click( function() {
		var scrollAnchor = $( this ).attr( "href" );
		var scrollOffset = $( scrollAnchor ).offset();
		$( "body, html" ).animate( { "scrollTop" : scrollOffset.top - 30 } );
	} );
	$(".bannerContainer:first-child").addClass("bannerActive");
	$(".bannerButton:first-child").addClass("bannerButtonActive");
	$(".photoContainer:first-child").addClass("photoActive");
	$(".topContainer:last-child").hide();
	$( ".bannerActive" ).css( { left : 0 } );
	$( ".photoActive" ).css( { left : 0 } );

	$( ".menuItem" ).hover( function() {
		$( this ).find( "img" ).stop( true, false ).animate( { "margin-top" : "-45px" } );
	}, function() {
		$( this ).find( "img" ).stop( true, false ).animate( { "margin-top" : "0px" } );
	} );

	setTimeout( photoScroll, photoDelay );

	bannerTimer = setTimeout( bannerScroll, bannerDelay );

	$( ".bannerButton" ).click( function() {
		$( ".bannerContainer" ).not( ".bannerActive" ).css( { left : -960 } );
		$( ".bannerButton" ).removeClass( "bannerButtonActive" );
		$( this ).addClass( "bannerButtonActive" );
		$( ".bannerActive" ).stop( true, false ).animate( { left : 960 } ).removeClass( "bannerActive" );
		$( ".bannerContainer[rel=" + $( this ).attr( "rel" ) + "]" ).stop( true, false ).animate( { left : 0 } ).addClass( "bannerActive" );
		clearTimeout( bannerTimer );
		bannerTimer = setTimeout( bannerScroll, bannerDelay );
	} );

	$( ".iconFloat" ).hover( function() {
		$( this ).animate( { "margin-top" : "-20px" }, 150 );
	}, function() {
		$( this ).stop( true, false ).animate( { "margin-top" : 0 }, 150 );
	} );
	$(".contentIcon").hover( function() {
		$( this ).animate( { "top" : "0" }, 150 );
	}, function() {
		$( this ).stop( true, false ).animate( { "top" : "20px" }, 150 );
	} );
	/** form send **/
	$("form").addClass("infield-active");
	if ($(".infield").length > 0)
		$(".infield").inFieldLabels();
	$(".mail-ajax").submit(function(e) {
		e.preventDefault();
		var $form = $(this);
		$form.append("<img src=\"/img/loader.gif\" class=\"loader\" />");
		if (!$form.valid()) return false;
		$.ajax({
			url:$form.attr("action"),
			type:$form.attr("method"),
			data:$form.serialize(),
			success: function(data) {
				$form.find(".loader").remove();
				$form.find("label[for=\"send\"]").remove();
				switch(data.status) {
				case 0: //success
					$form.html("<h3>Thanks for your enquiry!</h3>");
					break;
				case 1: //token fail
					$form.find("input[type='submit']").after("<label class=\"error\" for=\"send\">Possible spam submission detected. Please try again.</label>");
					break;
				case 2://token timeout
					$form.find("input[type='submit']").after("<label class=\"error\" for=\"send\">Anti-spam token expired. Please try again.</label>");
					break;
				case 3://validation fail
					$form.find("input[type='submit']").after("<label class=\"error\" for=\"send\">There was an error with the form. Please ensure you have filled out all fields.</label>");
					break;
				case 4://No post
					$form.find("input[type='submit']").after("<label class=\"error\" for=\"send\">Please fill out the form to submit your enquiry.</label>");
					break;
				case 5://email failure
					$form.find("input[type='submit']").after("<label class=\"error\" for=\"send\">There was an error sending your enquiry. Please try again.</label>");
					break;
				}
			},
			error: function() {
				$form.find(".loader").remove();
				$form.find("input[type='submit']").after("<label class=\"error\" for=\"send\">There was an error submitting your enquiry. Please check your internet connection and try again.</label>");
			}
		})
		;
	}).each(function(index, element) {
		$(element).append("<input type=\"hidden\" value=\"true\" name=\"ajax\"/>");
	});

});

export function photoScroll() {
	$( ".photoContainer" ).not( ".photoActive" ).css( { left : -350 } );
	var nextPhoto;
	if ( $( ".photoActive" ).attr( "rel" ) == 10 )
		nextPhoto = ( ".photoContainer[rel=1]" );
	else
		nextPhoto = $( ".photoActive" ).next( ".photoContainer" );
	$( ".photoActive" ).animate( { left : 350 } ).removeClass( "photoActive" );
	$( nextPhoto ).animate( { left : 0 } ).addClass( "photoActive" );
	setTimeout( photoScroll, photoDelay );
}

export function bannerScroll() {
	$( ".bannerContainer" ).not( ".bannerActive" ).css( { left : -960 } );
	var nextBanner;
	if ( $( ".bannerActive" ).attr( "rel" ) == 5 )
		nextBanner = $( ".bannerContainer[rel=1]" );
	else
		nextBanner = $( ".bannerActive" ).next( ".bannerContainer" );
	$( ".bannerActive" ).animate( { left : 960 } ).removeClass( "bannerActive" );
	$( nextBanner ).animate( { left : 0 } ).addClass( "bannerActive" );
	bannerTimer = setTimeout(bannerScroll, bannerDelay );
	$( ".bannerButton" ).removeClass( "bannerButtonActive" );
	$( ".bannerButton[rel=" + $( ".bannerActive" ).attr( "rel" ) + "]" ).addClass( "bannerButtonActive" );
}
